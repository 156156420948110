import '@/src/styles/main.css'

import galite from 'ga-lite'
import picoapp from 'picoapp'
import rola from 'rola'
import film from '@/src/js/film.js'
import scroll from '@/src/js/scroll.js'
import paralless from 'paralless'
import ReactPixel from 'react-facebook-pixel'

const parallax = paralless()

window.feature = {
  touch: !!(("ontouchstart" in window) || window.navigator && window.navigator.msPointerEnabled && window.MSGesture || window.DocumentTouch && document instanceof DocumentTouch)
}

const animations = rola()
const app = picoapp({
  // time: require('@/src/lib/time.js').default
  film,
  scroll
})

/**
 * init
 */
animations()
parallax()
app.mount()

export default app
galite('create', 'UA-39533042-1', 'auto')
galite('send', 'pageview')
ReactPixel.init('1576729352566661')
ReactPixel.pageView()