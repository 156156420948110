module.exports = {
  meta: {
    title: 'A24 Public Access',
    description: `38°34'35"N 121°28'47"W, 34°13'7"N 118°27'59"W, 43°42'19"N 71°06'44"W, 40°44'59"N 73°56'11"W, 33°57'24"N 83°22'49"W, 25°50'49"N 80°13'6"W`,
    image: ''
  },
  tagline: 'public access',
  films: [
    {
      name: 'Lady Bird',
      time: '8:00PM',
      image: '/ladybird.jpg',
      date: '07.20.2019',
      aspect: 54.05405405,
      location: `38°34'35"N 121°28'47"W`,
      locationLink: `https://www.google.com/maps/place/38%C2%B034'35.0%22N+121%C2%B028'47.0%22W/@38.5763889,-121.4819162,949m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d38.5763889!4d-121.4797222`
    },
    {
      name: 'THE BLING RING',
      time: '8:00PM',
      image: '/the-bling-ring.jpg',
      date: '07.27.2019',
      aspect: 54.05405405,
      location: `34°13'7"N 118°27'59"W`,
      locationLink: `https://www.google.com/maps/place/34%C2%B013'07.0%22N+118%C2%B027'59.0%22W/@34.2186111,-118.4685829,1004m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d34.2186111!4d-118.4663889`
    },
    {
      name: 'THE WITCH',
      time: '8:00PM',
      date: '08.06.2019',
      image: '/the-witch.jpg',
      aspect: 60.00976563,
      location: `43°42'19"N 71°06'44"W`,
      locationLink: `https://www.google.com/maps/place/43%C2%B042'19.0%22N+71%C2%B006'44.0%22W/@43.7052778,-71.1144162,878m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d43.7052778!4d-71.1122222`
    },
    {
      name: 'GOOD TIME',
      time: '7:30PM',
      image: '/good-time.jpg',
      date: '08.10.2019',
      aspect: 41.8359375,
      location: `40°44'59"N 73°56'11"W`,
      locationLink: `https://www.google.com/maps/place/40%C2%B044'59.0%22N+73%C2%B056'11.0%22W/@40.7497222,-73.9385829,920m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d40.7497222!4d-73.9363889`
    },
    {
      name: 'THE SPECTACULAR NOW',
      time: '8:00PM',
      date: '08.17.2019',
      image: '/the-spectacular-now.jpg',
      aspect: 41.8359375,
      location: `33°57'44"N 83°22'26"W`,
      locationLink: `https://www.google.com/maps/place/33%C2%B057'44.0%22N+83%C2%B022'26.0%22W/@33.9622266,-83.3760776,957m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d33.9622222!4d-83.3738889`
    },
    {
      name: 'MOONLIGHT',
      time: '7:30PM',
      image: '/moonlight.jpg',
      date: '08.24.2019',
      aspect: 41.8359375,
      location: `25°50'49"N 80°13'6"W`,
      locationLink: `https://www.google.com/maps/place/25%C2%B050'49.0%22N+80%C2%B013'06.0%22W/@25.8469444,-80.220522,1435m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x0!8m2!3d25.8469444!4d-80.2183333`
    }
  ]
}
