import { component } from 'picoapp'
import vsbl from 'vsbl'
import data from '@/data.js'
import srraf from 'srraf'

let direction = ''

const setData = (key, film) => {
  const screen = document.querySelector('.js-screen')
  const name = document.querySelector('.js-name')
  const link = document.querySelector('.js-link')
  const location = document.querySelector('.js-location')

  // screen.innerHTML = `<h1 class='cb'>${film.name} - ${key}</h1>`
  screen.style.paddingBottom = `${film.aspect}%`
  name.innerHTML = `<span>${film.name}</span><span>${film.date}</span><span>${film.time}</span>`
  location.innerHTML = `${film.location}`
  link.href = film.locationLink
}

export default component(({node}) => {
  const filmData = JSON.parse(node.querySelector('.js-film-data').innerHTML)
  const key = node.getAttribute('data-key')

  srraf(({y, py}) => {
    if (y < py) {
      direction = 'up'
    } else {
      direction = 'down'
    }
  })
  vsbl(node)(() => {
    setData(key, filmData)
  }, (event) => {
    
  }).update()
})