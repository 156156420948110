import { component } from 'picoapp'
import inView from 'in-view'
import app from '../index.js'
import vsbl from 'vsbl'
import data from '@/data.js'
import srraf from 'srraf'

let direction = ''

const setData = (key, film) => {
  const screen = document.querySelector('.js-screen')
  const name = document.querySelector('.js-name')
  const link = document.querySelector('.js-link')
  const location = document.querySelector('.js-location')

  // screen.innerHTML = `<h1 class='cb'>${film.name} - ${key}</h1>`
  screen.style.paddingBottom = `${film.aspect}%`
  if (film.image) {
    screen.innerHTML = `<img class='film__image' src='${film.image}' />`
    setTimeout(() => {
      screen.classList.add('active')
    }, 300)
  } else {
    screen.classList.remove('active')
    setTimeout(() => {
      screen.innerHTML = ``
    }, 300)
  }
  name.innerHTML = `<span>${film.name}</span><span>${film.date}</span><span>${film.time}</span>`
  location.innerHTML = `${film.location}`
  link.href = film.locationLink
}


export default component(({ node }) => {
  const website = node.querySelector('.js-site')

  function handleScreens() {
    const screens = node.querySelectorAll('.js-single-film')
    screens.forEach(screen => {
      const filmData = JSON.parse(screen.querySelector('.js-film-data').innerHTML)
      const key = node.getAttribute('data-key')

      srraf(({ y, py }) => {
        if (y < py) {
          direction = 'up'
        } else {
          direction = 'down'
        }
      })
      vsbl(screen)(() => {
        setData(key, filmData)
      }, (event) => {

      }).update()
    })
  }

  handleScreens()

  const parent = website.parentNode
  function scrollTrack(step, dom) {
    const clonedSite = dom.cloneNode(true)
    inView.offset(0)
    inView('.footer')
      .once('enter', () => {
        parent.appendChild(clonedSite)
        app.unmount()
        app.mount()
        handleScreens()
      })
      .once('exit', () => {
        const original = document.querySelectorAll('.site')
        // parent.removeChild(original[0]) // remove the first site
        // window.scrollTo({ top: 10, behavior: 'smooth' }) // reset the scroll
        setTimeout(() => {
          scrollTrack('.footer', dom)
        }, 10)
      })
  }
  scrollTrack('.footer', website)
})